import { getUserId, postTracking } from "actions/requests";
import "bootstrap/dist/css/bootstrap.css";
import { ScrollProvider } from "context/ScrollContext";
import cookie from "cookie";
import Cookies from "js-cookie";
import App from "next/app";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import "swiper/swiper.scss";
import "../fonts/stylesheet.css";
import "../styles/ckeditor.scss";
import "../styles/globals.scss";
import "../styles/variable.scss";

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const [lang, setLang] = useState(1);

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      const isValue =
        Cookies.get("name") || Cookies.get("phone") || Cookies.get("email");
      if (isValue && Cookies.get("isLeave") === "true") {
        Cookies.remove("isLeave");
        const values = {
          page: router.pathname,
          type: 1,
          status: 1,
          data_info: {
            name: Cookies.get("name"),
            phone: Cookies.get("phone"),
            email: Cookies.get("email"),
            company: Cookies.get("company"),
            content: Cookies.get("content"),
          },
        };
        postTracking(values);
      } else Cookies.remove("isLeave");
    };
    router.events.on("beforeHistoryChange", handleRouteChange);
    return () => {
      router.events.off("beforeHistoryChange", handleRouteChange);
    };
  }, [Cookies.get("isLeave")]);

  return (
    // <LangContext.Provider
    //   value={{
    //     value: lang,
    //     updateLang: setLang
    //   }}
    // >
    router.pathname.includes("/contact") ||
      router.pathname.includes("/survey/starting") ||
      router.pathname.includes("/news") ||
      router.pathname.includes("/news/[slug]") ||
      router.pathname.includes("/search") ? (
      <ScrollProvider>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
        >
          <Component {...pageProps} locale={router.locale} />
        </GoogleReCaptchaProvider>
      </ScrollProvider>
    ) : (
      <ScrollProvider>
        <Component {...pageProps} locale={router.locale} />
      </ScrollProvider>
    )
    // </LangContext.Provider>
  );
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const cookieStr = appContext.ctx.req?.headers.cookie || "";
  const user_id = cookie.parse(cookieStr)?.user_id;

  if (!user_id) {
    const userId = await getUserId();
    const currentTime = new Date();
    const expirationYear = new Date(
      currentTime.getFullYear() + 5,
      currentTime.getMonth(),
      currentTime.getDate()
    );

    appContext.ctx.res?.setHeader(
      "Set-Cookie",
      `user_id=${
        userId?.data.uuid
      }; expires=${expirationYear.toUTCString()}; path=/`
    );
  }

  return {
    pageProps: { ...appProps.pageProps },
  };
};

export default MyApp;
